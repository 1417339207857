<template>
  <div class="home">
    <div id="content">
      <h1>古星对战平台使用说明</h1>
      <h2>一、使用说明：</h2>
      <p>1、双击启动对战平台程序，提示安装tap网卡，点击yes</p>
      <p>2、点击注册，输入注册的账号密码，（3-8位数字或者字母）</p>
      <p>3、点击登录，设置游戏路径，点击启动游戏即可，进入游戏后选局域网 </p>
      <p>4、看不到主机和看见主机进不去的，关闭360等安全软件，关闭系统防火墙，或者把游戏的主程序添加到允许列表 </p>
      <strong>（PS:启动游戏的功能只是为了方便大家使用，可以直接双击游戏启动程序进入游戏）</strong>
      <h2>二、下载地址</h2>
      <div class="download">
        <div>
          <a href="./guxingsc.zip"><input type="button" value="1、绿色版本下载"></a>
        </div>
        <div>
            <a href="./setup.html"><input type="button" value="2、安装版本下载"></a>(缺少.net环境请下载此版本)
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>
<style>
#content{
  width: 60%;
  margin: 0 auto;
}
.download{
  display: flex;
}
.download div{
  flex: 1;
}
.download div a{
  color: black;
  text-decoration-line: none;
}
.download div input:hover{
  background-color: cornflowerblue;
  color: aliceblue;
  cursor: pointer;
}
h3{
  color: red;
}
</style>