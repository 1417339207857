import {request} from "@/network/request";


export function resetpass(username,password,newpass,renewpass){
    return request({
        method:'post',
        url:'/api/reset',
        headers:{
            'Content-Type': 'application/json'
        },
        data:JSON.stringify({
            UserName:username,
            PassWord:password,
            NewPassWord:newpass,
            ReNewPassWord:renewpass,
        })
    })
}